import { Button, Snackbar, TextField, Typography, withStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { getFerritiini } from '../../services/registrations'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  inputField: {
    marginTop: 50,
  },
  link: {
    marginTop: 10,
  }
})


const Ferritiini = ({ classes }) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [links, setLinks] = useState([])
  const [showDialog, setShowDialog] = useState(false)

  const sendPassword = useCallback(async () => {
    try {
      if (loading) return
      setLoading(true)
      const results = await getFerritiini(password)
      setLoading(false)
      console.log(results)
      setLinks(results && results.links)
    } catch (err) {
      setPassword('')
      setLoading(false)
      setShowDialog(true)
      setTimeout(() => {
        setShowDialog(false)
      }, 3000)
    }
  }, [loading, password, showDialog])

  document.title = 'Ferritiini'

  const hasLinks = Boolean(links && links.length)

  return (
    <div className={ classes.root }>
      {hasLinks &&
        <div className={classes.content}>
          {links.map(linkData => (
            <a className={classes.link} rel="noopener noreferrer" target="_blank" href={linkData.link}>{linkData.label}</a>
          ))}
        </div>
      }
      { !hasLinks &&
      <div className={ classes.content }>
        <TextField
          className={ classes.inputField }
          color="primary"
          label="Salasana"
          variant="outlined"
          value={ password }
          onChange={ event => setPassword(event.target.value) }
        />
        <Button
          onClick={ sendPassword }
          size="large"
          disabled={ loading || password.length < 3 }
          className={ classes.inputField }
          variant="outlined"
        >
          <span>Lähetä</span>
        </Button>
      </div>
      }
      <Snackbar
        open={ showDialog }
        message={
          <Typography>
            Väärä salasana
          </Typography>
        }
      />
    </div>
  )
}

export default withStyles(styles)(Ferritiini)